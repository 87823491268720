import {HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, shareReplay} from 'rxjs/operators';

const CACHE = new Map<string, Observable<HttpEvent<any>>>();

export const customHttpCachingInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, handle: HttpHandlerFn): Observable<HttpEvent<any>> => {
  if (request.method !== 'GET') {
    throw new Error('Request must be GET');
  }

  const url = request.urlWithParams;

  const cachedResponse = CACHE.get(url) || null;
  if (cachedResponse instanceof Observable) {
    return cachedResponse;
  }

  const req = handle(request).pipe(
    catchError(err => {
      CACHE.delete(url);
      return throwError(err);
    }),
    finalize(() => setTimeout(() => CACHE.delete(url), 5000)),
    shareReplay({bufferSize: 1, refCount: true})
  );
  CACHE.set(url, req);
  return req;
};

@Injectable({providedIn: 'root'})
class CustomHttpHandler implements HttpHandler {
  constructor(private httpBackend: HttpBackend) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return customHttpCachingInterceptor(req, req => this.httpBackend.handle(req));
  }
}

let cachedCustomHttpClient: HttpClient;
export const getCustomHttpClient = (): HttpClient =>
  cachedCustomHttpClient ? cachedCustomHttpClient : (cachedCustomHttpClient = new HttpClient(inject(CustomHttpHandler)));
