import {Injectable} from '@angular/core';
import {SubscriptionResult} from 'apollo-angular';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {PanelInfoGQL, PanelInfoSubscription} from './panel.generated';

@Injectable({providedIn: 'root'})
export class PanelStatusService {
  private status$: Observable<SubscriptionResult<PanelInfoSubscription>>;

  constructor(private panelInfo: PanelInfoGQL) {}

  subscribe(): Observable<SubscriptionResult<PanelInfoSubscription>> {
    if (!this.status$) {
      this.status$ = this.panelInfo.subscribe().pipe(shareReplay({bufferSize: 1, refCount: true}));
    }
    return this.status$;
  }
}
