import {inject} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {DeploymentType} from 'api/entities';
import {SubscriptionResult} from 'apollo-angular';
import {Observable, of, retry} from 'rxjs';
import {map, share, switchMap, take} from 'rxjs/operators';
import {PanelError, PanelStatus} from 'types';
import {apiVersion} from '../../api-version.constant';
import {PanelStatusService} from '../../panel-status.service';
import {PanelInfoSubscription} from '../../panel.generated';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {SystemInfoI} from '../../shared/entities/system/system.interface';
import {getCustomHttpClient} from '../interceptors/custom-http-caching.interceptor';

export const panelStatus: {status: PanelStatus; errors: PanelError[]} = {
  status: null,
  errors: []
};

export const checkPanelStatus =
  (router: Router, loggedIn: boolean = false, fromGuard: boolean = false) =>
  // eslint-disable-next-line complexity
  (response: SubscriptionResult<PanelInfoSubscription>): boolean | UrlTree => {
    panelStatus.status = response.data.panelInfo.panelStatus;
    panelStatus.errors = response.data.panelInfo.panelErrors;
    if (response.data.panelInfo.panelStatus === PanelStatus.Unregistered) {
      return router.createUrlTree(['activate', 'panel-registration']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Registered) {
      return router.createUrlTree(['activate', 'panel-integrate']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Integrated) {
      return router.createUrlTree(['activate', 'panel-activate']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Activated) {
      return router.createUrlTree(['activate', 'panel-sync']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Unlinking) {
      return router.createUrlTree(['activate', 'panel-unlink']);
    } else if (!loggedIn && [PanelStatus.Importing, PanelStatus.Error].includes(response.data.panelInfo.panelStatus)) {
      return router.createUrlTree(['activate', 'panel-import']);
    } else if (!loggedIn && location.href.includes('activate/') && !fromGuard && response.data.panelInfo.panelStatus === PanelStatus.Ready) {
      return router.createUrlTree(['/']);
    }
    return true;
  };

export const panelLoadingStatus: {obs$: Observable<any>} = {
  obs$: null
};

export const systemInfo: Partial<SystemInfoI> = {};

export function panelStatusGuard(): Observable<boolean | UrlTree> {
  if (systemInfo.deploymentType && !UserSessionService.isOnSitePanel(systemInfo.deploymentType)) {
    return of(true);
  }
  const router = inject(Router);
  const http = getCustomHttpClient();
  const panelStatusService = inject(PanelStatusService);
  return (panelLoadingStatus.obs$ = http.get('health/entities').pipe(
    retry({delay: 5000}),
    switchMap(() => http.get<SystemInfoI>(apiVersion + 'system/staticInfo')),
    switchMap(response => {
      Object.assign(systemInfo, response);
      if (UserSessionService.isOnSitePanel(response.deploymentType)) {
        return panelStatusService.subscribe().pipe(take(1), map(checkPanelStatus(router, location.href.includes('client/'), true)));
      } else {
        return of(true);
      }
    }),
    share()
  ));
}
